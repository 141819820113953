<script setup lang="ts">
import {
  HomeFilled,
  Switch,
  WalletFilled,
  ShoppingBag,
} from "@element-plus/icons-vue";
const { data: user } = useAuth();
const { t } = useI18n();
const localePath = useLocalePath();
</script>

<template>
  <div>
    <div class="mobile-bumper"></div>
    <div class="mobile-menu">
      <Transition>
        <el-row type="flex" justify="center" :gutter="20" v-if="user">
          <el-col :span="6">
            <NuxtLink
              :to="localePath('/')"
              class="link-title pt-3 pb-2 decoration-none"
            >
              <el-icon><HomeFilled /></el-icon>
              <span class="link-title-label">{{
                t("c-footer-mobilemenu-home")
              }}</span>
            </NuxtLink>
          </el-col>
          <el-col :span="6">
            <NuxtLink
              :to="localePath('/trade')"
              class="link-title pt-3 pb-2 decoration-none"
            >
              <el-icon><Switch /></el-icon>
              <span class="link-title-label">{{
                t("c-footer-mobilemenu-trade")
              }}</span>
            </NuxtLink>
          </el-col>
          <el-col :span="6">
            <NuxtLink
              :to="localePath('/trade/orders')"
              class="link-title pt-3 pb-2 decoration-none"
            >
              <el-icon><ShoppingBag /></el-icon>
              <span class="link-title-label">{{
                t("c-footer-mobilemenu-orders")
              }}</span>
            </NuxtLink>
          </el-col>
          <el-col :span="6">
            <NuxtLink
              :to="localePath('/user/wallet')"
              class="link-title pt-3 pb-2 decoration-none"
            >
              <el-icon><WalletFilled /></el-icon>
              <span class="link-title-label">{{
                t("c-footer-mobilemenu-wallet")
              }}</span>
            </NuxtLink>
          </el-col>
        </el-row>
      </Transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mobile-menu {
  position: fixed;
  overflow: hidden;
  bottom: 0;
  right: 0;
  left: 0;
  top: auto;
  background: var(--el-bg-color);
  border-top: 1px solid var(--el-border-color);
  z-index: 2001;

  .link-title {
    display: block;
    text-align: center;

    &-label {
      display: block;
    }
  }
}
.mobile-bumper {
  height: 60px;
}
</style>
